import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import type { Status } from '../gauge-bar/gauge-bar'

interface TooltipProps {
  align?: 'start' | 'end' | undefined
  position: number
  status: Status
  children: ReactNode
  className?: string
  arrowOverlap: number
  [dataAttrib: `data-${string}`]: string
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ align, position, status, children, className, arrowOverlap, ...dataAtribs }, ref) => {
    return (
      <div
        {...dataAtribs}
        ref={ref}
        style={
          {
            left: ['start', 'end'].includes(align || '') ? 'auto' : `${position}%`,
            '--overlap': `${arrowOverlap}px`,
            '--colour-status': `var(--colour-text-${status}, var(--colour-text-neutral))`,
          } as CSSProperties
        }
        className={twMerge(
          !['start', 'end'].includes(align || '') && '-translate-x-1/2',
          `
        relative 
        shrink-0 grow-0 
        rounded-[4px] border border-mist bg-white 
        px-2 py-1
        text-base
        font-normal
        after:absolute
        after:bottom-[-4px]
        after:left-[calc(50%+var(--overlap))]
        after:h-[5px] after:w-[5px] 
        after:-translate-x-1/2
        after:rotate-[45deg]
        after:border
        after:border-mist
        after:border-l-transparent after:border-t-transparent 
        after:bg-white
        after:content-[var(--arrow-content,'')]
        [&_strong]:text-[--colour-status]
        `,
          className,
        )}
      >
        {children}
      </div>
    )
  },
)

export default Tooltip
