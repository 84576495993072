import { gql } from '@apollo/client'

export const GET_USER_FACILITY_CONFIGURATIONS = gql`
  query UserFacilityConfigurations($facilityByIdInput: FacilityByIdInput!) {
    facilityById(input: $facilityByIdInput) {
      id
      name
    }
    clinicianUser {
      id: email
      email
      facilityConfigurations {
        label
        facility {
          id
          name
        }
      }
    }
  }
`
