import type { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import track from '../../../service/track/track'
import getRedirectFromSearch from '../../../utility/get-redirect-from-search'

const RedirectFromSearch: FC<{ children: ReactNode }> = ({ children }) => {
  const { search, pathname } = useLocation()

  if (search) {
    const redirectTo = getRedirectFromSearch(search)

    if (redirectTo) {
      track('redirect:rd-query-parameter', {
        originalUrl: pathname,
        redirectedTo: redirectTo.pathname,
      })

      return <Navigate to={redirectTo} />
    }
  }

  return <>{children}</>
}

export default RedirectFromSearch
