import { type QueryResult, useQuery } from '@apollo/client'
import { GET_CURRENT_NOTIFICATIONS_QUERY } from '../../../graphql/queries/get-current-notifications'
import type { CurrentNotifications } from '../../../graphql/type/CurrentNotifications'

export interface useNotificationsArguments {
  facilityId?: string
}

const useNotifications = ({ facilityId }: useNotificationsArguments): QueryResult<CurrentNotifications> => {
  return useQuery<CurrentNotifications>(GET_CURRENT_NOTIFICATIONS_QUERY, {
    pollInterval: 15_000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        facilityId,
      },
    },
  })
}

export default useNotifications
