import { v4 as uuidv4 } from 'uuid'

const { VITE_VERSION } = import.meta.env

type AdditionalProperties = Record<string, unknown> & {
  release: string
  email?: string
}

export interface Event {
  additionalProperties: AdditionalProperties
  eventId: string
  facilityId?: string
  visitId?: string
  language?: string
  name: string
  referrer?: string
  url: string
  viewportHeight: number
  viewportWidth: number
}

export const createEvent = ({
  eventName,
  facilityId,
  additionalProperties,
  email,
}: {
  eventName: string
  facilityId?: string
  additionalProperties?: Record<string, unknown>
  email?: string
}): Event => {
  return {
    additionalProperties: {
      ...additionalProperties,
      email,
      release: VITE_VERSION,
    },
    eventId: uuidv4(),
    facilityId,
    language: window?.navigator?.language,
    name: `vital-response-clinician:${eventName}`,
    referrer: window.document.referrer || undefined, // This might be undefined
    url: window.location.href,
    viewportHeight: window.innerHeight,
    viewportWidth: window.innerWidth,
  }
}
