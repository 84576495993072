import {
  Children,
  type FC,
  type HTMLProps,
  type ReactElement,
  type ReactNode,
  cloneElement,
  isValidElement,
} from 'react'
import { twMerge } from 'tailwind-merge'

export interface ButtonGroupProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode
  className?: string
  nowrap?: boolean
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  children,
  className,
  nowrap,
  ...htmlElementAttributes
}): ReactElement => {
  const kids = Children.toArray(children)

  return (
    <div
      className={twMerge('flex w-full gap-2', nowrap ? 'flex-nowrap' : 'flex-wrap', className)}
      {...htmlElementAttributes}
    >
      {kids.map((child) => {
        if (isValidElement(child)) {
          return cloneElement(child as ReactElement, {
            className: twMerge(child.props.block === true && '!w-auto shrink-0 grow', child.props?.className),
          })
        }

        return null
      })}
    </div>
  )
}
