import { getValueFromLocalStorage } from '../../../service/storage'

const useIsLoggedIn = (): boolean => {
  if (import.meta.env.MODE === 'test') {
    return true
  }

  const idToken = getValueFromLocalStorage<string>('id-token')

  return !!idToken
}

export default useIsLoggedIn
