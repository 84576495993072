import type { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { SSO_URL } from './auth-zero-url-config'

// Auth0 Redirect for clinicians accessing SSO directly through their IDP
const Auth0Login: FC = () => {
  return <Navigate to={SSO_URL} />
}

export default Auth0Login
