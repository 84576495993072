import { gql } from '@apollo/client'

export const USER_PERMISSIONS_QUERY = gql`
  query UserPermissions {
    clinicianUser {
      id: email
      email
      facilityConfigurations {
        label
        facility {
          id
          name
        }
      }
    }
  }
`
