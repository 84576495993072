import { ApolloProvider } from '@apollo/client'
import { ToastProvider } from '@katoa/anatomy'
import { type ReactElement, type ReactNode, Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { configureClient } from '../client'
import { Paths } from '../config/app'
import ComposeProviders from '../providers/compose-providers/compose-providers'
import OneSignalProvider from '../providers/onesignal-provider/onesignal-provider'
import lazy from '../utility/lazy'
import Auth0Login from './auth/auth-zero-login'
import ProtectedPage from './auth/protected-page/protected-page'
import { FacilitySpecificRoutes } from './routes/facility-specific-routes'
import DelayedLoader from './ui-components/delayed-loader/delayed-loader'

/*
    Lazy loaded
*/

// Prefetched
const Login = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "login" */ '../pages/login/login'))

const Logout = lazy(() => import(/* webpackChunkName: "logout" */ '../pages/logout/logout'))

const Auth0Callback = lazy(
  () => import(/* webpackChunkName: "auth-zero-callback" */ '../pages/auth-zero-callback/auth-zero-callback'),
)

const SSOLandingPage = lazy(() => import(/* webpackChunkName: "auth-zero-login" */ '../pages/login/sso-landing'))

// Setup Apollo
const apolloClient = configureClient()

const apolloProviderWrapper =
  () =>
  ({ children }: { children: ReactNode }): ReactElement => (
    <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
  )

const App = (): JSX.Element => {
  return (
    <ComposeProviders providers={[apolloProviderWrapper(), ToastProvider, OneSignalProvider]}>
      <BrowserRouter>
        <Suspense fallback={<DelayedLoader />}>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedPage>
                  <Navigate to={Paths.Alerts} />
                </ProtectedPage>
              }
            />

            {/* Auth */}
            <Route path={Paths.Login} element={<Login />} />

            <Route path={Paths.Logout} element={<Logout />} />

            <Route path={Paths.Auth0Callback} element={<Auth0Callback />} />

            <Route path={Paths.Auth0Login} element={<Auth0Login />} />

            <Route path={Paths.SSOLandingPage} element={<SSOLandingPage />} />

            <Route
              path="/:facilityId/*"
              element={
                <ProtectedPage>
                  <FacilitySpecificRoutes />
                </ProtectedPage>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ComposeProviders>
  )
}

export default App
