import { Slot } from '@radix-ui/react-slot'
import { type ReactNode, forwardRef, useMemo } from 'react'
import { SlotButtonContext } from './context'
import { getButtonClasses } from './get-button-classes'
import { Inner } from './inner'
import type { ButtonBaseProps } from './types'

interface SlotButtonProps extends ButtonBaseProps {
  children: ReactNode
  asChild?: boolean
}

const SlotButton = forwardRef<HTMLButtonElement, SlotButtonProps>(
  (
    {
      asChild,
      className,
      children,
      intent = 'positive',
      size,
      loading,
      block,
      success,
      soft,
      collapsed,
      iconBefore,
      iconAfter,
      disabled,
      hover,
      active,
      ...attributes
    },
    ref,
  ) => {
    const classes = getButtonClasses({
      className,
      children,
      intent,
      size,
      loading,
      block,
      success,
      soft,
      collapsed,
      iconBefore,
      iconAfter,
      disabled,
      hover,
      active,
    })

    const Element = asChild ? Slot : 'button'

    const innerProps = useMemo(
      () => ({ loading, success, iconBefore, iconAfter }),
      [loading, success, iconBefore, iconAfter],
    )

    return (
      <SlotButtonContext.Provider value={innerProps}>
        <Element
          ref={ref}
          className={classes}
          {...attributes}
          {...(loading || disabled ? { onClick: undefined } : {})}
          disabled={disabled}
        >
          {Element === 'button' ? <Inner>{children}</Inner> : children}
        </Element>
      </SlotButtonContext.Provider>
    )
  },
)

const extended = Object.assign(SlotButton, { Inner })

export { extended as SlotButton, type SlotButtonProps }
