import type { FC, ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { Caption } from '../typography/caption'

export interface RemainingCharactersProps {
  currentLength?: number
  maximum: number
  id: string
}

export const RemainingCharacters: FC<RemainingCharactersProps> = ({ currentLength, id, maximum }): ReactElement => {
  const remaining = currentLength ? maximum - currentLength : maximum

  return (
    <Caption
      asChild={true}
      tertiary={true}
      className={twMerge(remaining < 0 && 'text-pohutukawa opacity-100')}
      id={id}
      aria-live="polite"
      aria-atomic="true"
      data-testid="components:remaining-characters"
    >
      <small>
        {remaining >= 0 && (
          <>
            You have {remaining}
            {remaining === 1 ? ' character ' : ' characters '}
            remaining
          </>
        )}

        {remaining < 0 && (
          <>
            You have {-remaining}
            {remaining === -1 ? ' character ' : ' characters '}
            too many
          </>
        )}
      </small>
    </Caption>
  )
}
