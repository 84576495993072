import type { FC } from 'react'
import { Illustration, type IllustrationProps } from './illustration'

export const GeneralErrorIllustration: FC<Omit<IllustrationProps, 'children'>> = (props) => {
  return (
    <Illustration {...props}>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="component:illustration:general-error"
      >
        <g clipPath="url(#clip0_650_356)">
          <path
            d="M102.512 14.1972C102.476 21.9392 96.1702 28.1856 88.4284 28.1494L59.4403 28.0133C51.6983 27.977 45.4519 21.6715 45.4881 13.9297C45.5244 6.18773 51.8299 -0.0586697 59.5717 -0.0224841L88.5596 0.113459C96.3016 0.149786 102.548 6.45523 102.512 14.1972V14.1972Z"
            fill="#B3B9C1"
          />
          <path
            d="M74.0657 0.0455426L73.9343 28.0813L59.4402 28.0133C51.6983 27.977 45.4519 21.6715 45.4881 13.9297C45.5244 6.18772 51.8298 -0.0586845 59.5717 -0.0224986L74.0657 0.0455426Z"
            fill="var(--highlight-colour)"
          />
          <path
            d="M45.4874 13.9298C45.4875 13.7856 45.4922 13.641 45.4969 13.4967C45.5004 13.385 45.5073 13.2714 45.5153 13.1596C47.0007 19.2732 52.4977 23.8231 59.0716 23.8535L73.953 23.9239L73.9345 28.0804L59.4397 28.0139C51.6994 27.9773 45.4517 21.6719 45.4874 13.9298V13.9298Z"
            fill="#D1E0E0"
          />
          <path d="M57.0256 9.01535L57.0256 4.67975L61.3612 4.67975L61.3612 9.01535L57.0256 9.01535Z" fill="#F8FAFC" />
          <path
            d="M64.6129 9.01535L64.6129 4.67974L90.6266 4.67974L90.6266 9.01535L64.6129 9.01535Z"
            fill="url(#paint0_linear_650_356)"
          />
          <g style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M45.4874 13.9299C45.4882 13.673 45.4963 13.417 45.5153 13.1597C47.0007 19.2732 52.4977 23.8231 59.0715 23.8536L88.0582 23.9902C95.5435 24.025 101.684 18.1889 102.114 10.8083C102.379 11.8927 102.518 13.0294 102.512 14.1976C102.477 21.9397 96.1702 28.1854 88.4281 28.1497L59.4396 28.0141C51.6994 27.9773 45.4518 21.672 45.4874 13.9299V13.9299Z"
              fill="url(#paint1_linear_650_356)"
            />
          </g>
          <path
            d="M68.4964 102.155C73.9231 107.677 82.7984 107.754 88.3201 102.327L108.995 82.0078C114.517 76.581 114.594 67.7058 109.167 62.184C103.74 56.6623 94.8651 56.5854 89.3434 62.012L68.6684 82.3311C63.1467 87.7579 63.0696 96.6333 68.4964 102.155V102.155Z"
            fill="#B3B9C1"
          />
          <path
            d="M79.0059 72.1716L98.6576 92.1675L108.995 82.0078C114.517 76.5811 114.594 67.7058 109.167 62.184C103.74 56.6623 94.8651 56.5854 89.3434 62.012L79.0059 72.1716Z"
            fill="var(--highlight-colour)"
          />
          <path
            d="M109.168 62.1836C109.067 62.0804 108.963 61.9801 108.859 61.88C108.778 61.8023 108.694 61.7258 108.61 61.6513C111.811 67.0678 111.046 74.1625 106.357 78.7701L95.744 89.2018L98.6568 92.1669L108.996 82.0078C114.516 76.582 114.594 67.706 109.168 62.1836V62.1836Z"
            fill="#D1E0E0"
          />
          <path d="M97.4723 66.7114L94.4479 63.605L91.3414 66.6294L94.3659 69.7359L97.4723 66.7114Z" fill="#F8FAFC" />
          <path
            d="M92.036 72.0043L89.0116 68.8979L70.3729 87.0447L73.3974 90.1512L92.036 72.0043Z"
            fill="url(#paint2_linear_650_356)"
          />
          <g style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M109.168 62.1836C108.988 62.0002 108.804 61.8224 108.61 61.6513C111.811 67.0678 111.046 74.1625 106.357 78.7701L85.6839 99.0889C80.345 104.335 71.8743 104.437 66.4171 99.4496C66.9841 100.411 67.6777 101.322 68.4964 102.155C73.9228 107.678 82.7982 107.754 88.3206 102.327L108.996 82.0078C114.516 76.582 114.594 67.706 109.168 62.1836V62.1836Z"
              fill="url(#paint3_linear_650_356)"
            />
          </g>
          <path
            d="M116.099 37.791C122.283 36.8977 126.555 31.0409 125.641 24.7094C124.726 18.3779 118.971 13.9694 112.787 14.8626C106.603 15.7559 102.331 21.6127 103.246 27.9442C104.16 34.2757 109.915 38.6843 116.099 37.791Z"
            fill="#D9DCE0"
          />
          <path
            d="M123.505 33.2959C119.743 38.4067 112.596 39.439 107.541 35.6018C107.359 35.4637 107.182 35.32 107.011 35.1727C111.88 37.7202 117.976 36.4161 121.349 31.8338C124.973 26.9096 124.133 19.9945 119.544 16.0642C120.102 16.3558 120.644 16.6984 121.164 17.0929C126.22 20.9302 127.268 28.1849 123.505 33.2959V33.2959Z"
            fill="url(#paint4_linear_650_356)"
          />
          <path
            d="M119.296 30.7677C119.064 30.8012 118.819 30.7462 118.616 30.5945L109.269 23.6069C108.863 23.3037 108.78 22.7289 109.084 22.3234C109.387 21.9177 109.962 21.8347 110.367 22.138L119.714 29.1256C120.12 29.4288 120.203 30.0036 119.9 30.4091C119.748 30.6119 119.529 30.7341 119.296 30.7677Z"
            fill="#B3B9C1"
          />
          <path
            d="M112.966 60.7485C117.475 65.0738 124.72 64.8376 129.149 60.2208C133.577 55.6041 133.511 48.3551 129.002 44.0298C124.492 39.7045 117.247 39.9408 112.819 44.5575C108.39 49.1743 108.456 56.4232 112.966 60.7485Z"
            fill="#D9DCE0"
          />
          <path
            d="M121.066 63.8204C114.722 63.9996 109.511 59.0009 109.425 52.6554C109.422 52.4271 109.427 52.1987 109.438 51.9737C110.425 57.3787 115.204 61.3818 120.892 61.2212C127.004 61.0486 131.937 56.1309 132.212 50.0952C132.326 50.7145 132.389 51.3531 132.398 52.0056C132.484 58.3521 127.41 63.6421 121.066 63.8204V63.8204Z"
            fill="url(#paint5_linear_650_356)"
          />
          <path
            d="M120.469 58.9473C120.3 58.7848 120.192 58.5578 120.187 58.3045L119.944 46.6369C119.933 46.1305 120.335 45.7115 120.842 45.701C121.348 45.6906 121.767 46.0925 121.778 46.5988L122.021 58.2664C122.031 58.7727 121.629 59.1918 121.123 59.2023C120.87 59.2076 120.638 59.1097 120.469 58.9473Z"
            fill="#B3B9C1"
          />
          <path
            d="M84.1746 145.096C89.4916 139.493 89.1517 130.538 83.4153 125.094C77.6789 119.651 68.7183 119.78 63.4013 125.383C58.0842 130.986 58.4242 139.941 64.1606 145.385C69.897 150.828 78.8575 150.699 84.1746 145.096Z"
            fill="var(--highlight-colour)"
          />
          <path
            d="M87.9184 135.062C88.1818 142.903 82.0371 149.378 74.1934 149.526C73.9112 149.531 73.6289 149.527 73.3506 149.515C80.0257 148.259 84.9426 142.325 84.7065 135.295C84.4527 127.74 78.341 121.674 70.878 121.374C71.6428 121.23 72.4317 121.147 73.2384 121.132C81.0832 120.983 87.6561 127.221 87.9184 135.062V135.062Z"
            fill="url(#paint6_linear_650_356)"
          />
          <path
            d="M81.8984 135.832C81.6986 136.043 81.4187 136.177 81.1056 136.185L66.684 136.563C66.0581 136.579 65.5374 136.085 65.5211 135.459C65.505 134.833 65.999 134.312 66.6248 134.296L81.0464 133.919C81.6723 133.902 82.193 134.396 82.2092 135.022C82.2174 135.335 82.098 135.622 81.8984 135.832Z"
            fill="#F8FAFC"
          />
          <path
            d="M19.5599 25.7042C19.7235 31.9505 25.0402 36.8784 31.4352 36.7109C37.8302 36.5435 42.8818 31.3441 42.7183 25.0978C42.5547 18.8515 37.2379 13.9237 30.8429 14.0911C24.4479 14.2586 19.3963 19.4579 19.5599 25.7042Z"
            fill="#D9DCE0"
          />
          <path
            d="M23.1576 33.5849C18.5221 29.2507 18.3331 22.032 22.7354 17.4612C22.8938 17.2967 23.0573 17.1373 23.2235 16.9851C20.124 21.5219 20.7058 27.7287 24.862 31.6148C29.3283 35.7907 36.2942 35.7648 40.7344 31.6673C40.3796 32.1874 39.9758 32.6862 39.5232 33.1564C35.1207 37.7284 27.7932 37.9203 23.1576 33.5849V33.5849Z"
            fill="url(#paint7_linear_650_356)"
          />
          <path
            d="M26.1609 29.7012C26.1547 29.4665 26.238 29.2296 26.4124 29.0458L34.4457 20.5807C34.7943 20.2133 35.3748 20.1981 35.7421 20.5468C36.1095 20.8957 36.1247 21.476 35.776 21.8433L27.7428 30.3084C27.3941 30.6758 26.8136 30.691 26.4464 30.3424C26.2627 30.1681 26.167 29.9358 26.1609 29.7012Z"
            fill="#B3B9C1"
          />
          <ellipse
            rx="2.282"
            ry="2.281"
            transform="matrix(0.788726 -0.614745 0.614746 0.788725 18.202 107.391)"
            fill="#D1E0E0"
          />
          <ellipse
            rx="2.282"
            ry="2.282"
            transform="matrix(0.788725 -0.614746 0.614745 0.788726 140.452 9.95204)"
            fill="#D1E0E0"
          />
          <ellipse
            cx="121.587"
            cy="108.087"
            rx="3.803"
            ry="3.803"
            transform="rotate(-37.9334 121.587 108.087)"
            fill="#D9DCE0"
          />
          <ellipse
            cx="14.337"
            cy="3.837"
            rx="3.803"
            ry="3.803"
            transform="rotate(-37.9334 14.3367 3.83672)"
            fill="#D9DCE0"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_650_356"
            x1="64.613"
            y1="6.848"
            x2="90.627"
            y2="6.848"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8FAFC" />
            <stop offset="1" stopColor="#F8FAFC" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_650_356"
            x1="91.337"
            y1="-.262"
            x2="57.826"
            y2="32.365"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#687485" />
            <stop offset="1" stopColor="#687485" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_650_356"
            x1="90.524"
            y1="70.451"
            x2="71.885"
            y2="88.598"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8FAFC" />
            <stop offset="1" stopColor="#F8FAFC" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_650_356"
            x1="66.417"
            y1="83.999"
            x2="113.187"
            y2="83.999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#687485" />
            <stop offset="1" stopColor="#687485" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_650_356"
            x1="114.428"
            y1="16.803"
            x2="117.432"
            y2="37.599"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B3B9C1" />
            <stop offset="1" stopColor="#B3B9C1" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_650_356"
            x1="128.482"
            y1="46.517"
            x2="113.937"
            y2="61.681"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B3B9C1" />
            <stop offset="1" stopColor="#B3B9C1" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_650_356"
            x1="66.48"
            y1="126.009"
            x2="85.321"
            y2="143.888"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#687485" />
            <stop offset="1" stopColor="#687485" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_650_356"
            x1="40.599"
            y1="26.5"
            x2="19.595"
            y2="27.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B3B9C1" />
            <stop offset="1" stopColor="#B3B9C1" stopOpacity=".2" />
          </linearGradient>
          <clipPath id="clip0_650_356">
            <rect width="150" height="150" fill="#fff" />
          </clipPath>
        </defs>
      </svg>
    </Illustration>
  )
}
