import { gql } from '@apollo/client'

export const ALERTS_TOGGLE_QUERY = gql`
  query AlertsToggleQuery(
    $facilityInput: FacilityByIdInput!
    $clinicianUserFacilityConfigurationInput: ClinicianUserFacilityConfigurationInput!
  ) {
    facilityById(input: $facilityInput) {
      id
      notificationGroups {
        id
        label
        locationIds
      }
      locations {
        id
        label
      }
    }
    clinicianUserFacilityConfiguration(
      input: $clinicianUserFacilityConfigurationInput
    ) {
      label
      facilityId
      email
      notificationGroupIds
      notificationsEnabled
      locationIds
    }
  }
`
