import { mdiAlertCircle, mdiCheck, mdiCircleSlice5, mdiLoading } from '@mdi/js'
import type { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import type { IconSize, Size } from '../../types'
import { Icon } from '../icon/icon'
import { Body } from '../typography/body'

export interface StatusProps {
  className?: string
  status: 'inprogress' | 'loading' | 'success' | 'error' | null
  customIcons?: {
    inprogress?: string
    success?: string
    loading?: string
    error?: string
  }
  message?: ReactNode
  messagePosition?: string
  size?: Size
}

export const Status: FC<StatusProps> = ({
  className,
  status,
  customIcons,
  message,
  messagePosition = 'after',
  size = 'kiwi',
  ...attributes
}) => {
  let iconMarkup = null
  let iconSize: IconSize = 3
  let typeStyle = ''

  const icons = {
    loading: customIcons?.loading || mdiLoading,
    success: customIcons?.success || mdiCheck,
    error: customIcons?.error || mdiAlertCircle,
    inprogress: customIcons?.inprogress || mdiCircleSlice5,
  }

  switch (size) {
    case 'piwakawaka':
      typeStyle = 'text-sm'
      iconSize = 1
      break

    case 'kiwi':
      iconSize = 3
      break

    case 'kea':
      iconSize = 4
      break

    case 'moa':
      typeStyle = 'text-lg'
      iconSize = 7
      break

    default:
      break
  }

  if (status !== null) {
    iconMarkup = <Icon data-testid={`icon:${status}`} size={iconSize} path={icons[status]} />
  }

  if (status === 'loading') {
    iconMarkup = <Icon className="animate-spin" data-testid="icon:loading" size={iconSize} path={icons.loading} />
  }

  return (
    <div
      data-testid={`component:status:${status ?? 'none'}`}
      className={twMerge(
        'flex items-center gap-1',
        status === 'success' && 'text-kiwi',
        status === 'error' && 'text-pohutukawa',
        status === 'inprogress' && 'text-kereru',
        status === 'loading' && 'text-midnight',
        className,
      )}
      {...attributes}
    >
      {messagePosition === 'after' && iconMarkup}

      {message && <Body className={twMerge('text-inherit', typeStyle)}>{message}</Body>}

      {messagePosition === 'before' && iconMarkup}
    </div>
  )
}
