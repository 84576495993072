import type { FC, HTMLProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface TruncateProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode
}

export const Truncate: FC<TruncateProps> = ({ children, className }) => (
  <div className={twMerge('w-full truncate', className)}>{children}</div>
)
