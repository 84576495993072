import { mdiArrowLeft, mdiClose } from '@mdi/js'
import { AnimatePresence, m } from 'framer-motion'
import {
  Children,
  type ComponentPropsWithoutRef,
  type FC,
  type KeyboardEvent,
  type ReactElement,
  type ReactNode,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import { twMerge } from 'tailwind-merge'
import type { Size } from '../../types'
import { Button } from '../button/button'
import { Divider } from '../divider/divider'
import { LazyMotion } from '../lazy-motion/lazy-motion'
import { Stack } from '../stack/stack'
import { Status } from '../status/status'
import { Body } from '../typography/body'
import { HeadingMid } from '../typography/heading-mid'
import { Subheading } from '../typography/subheading'
import type { DialogContentProps } from './dialog-content'
import type { DialogFooterProps } from './dialog-footer'

export interface DialogProps
  extends Omit<ComponentPropsWithoutRef<'div'>, keyof ComponentPropsWithoutRef<typeof m.div>> {
  isVisible?: boolean
  disableAnimation?: boolean
  onRequestDismiss?: (() => void) | undefined
  onBack?: () => void
  size?: Exclude<Size, 'kea'>
  loading?: boolean
  dismissible?: boolean
  children:
    | ReactElement<DialogFooterProps | DialogContentProps>[]
    | ReactElement<DialogFooterProps | DialogContentProps>

  pretitle?: string
  title?: string
  subtitle?: string | { label?: string; href?: string; onClick?: () => void }
  className?: string
  subHeaderContent?: ReactNode
}

export const Dialog: FC<DialogProps> = ({
  children,
  dismissible = true,
  disableAnimation = false,
  isVisible = false,
  loading = false,
  onRequestDismiss,
  onBack,
  pretitle,
  size = 'piwakawaka',
  subtitle,
  title,
  className,
  subHeaderContent,
  ...attributes
}) => {
  const WINDOW_HEIGHT = window.innerHeight

  const dialogRoot = useRef(document.getElementById('portal'))

  const [visible, setVisible] = useState(isVisible)

  const dialogRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setVisible(isVisible)
  }, [isVisible])

  const handleCloseClick = (): void => {
    if (dismissible) {
      setVisible(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Escape' && dismissible) {
      setVisible(false)
    }
  }

  const hasSubtitleText = typeof subtitle === 'string'
  const hasSubtitleLink = typeof subtitle === 'object' && subtitle.label && subtitle.href

  if (dialogRoot.current) {
    return createPortal(
      <LazyMotion>
        <AnimatePresence onExitComplete={onRequestDismiss ?? (() => undefined)}>
          {visible && (
            <section className="m-0 p-0">
              <RemoveScrollBar />
              <FocusLock returnFocus={true} disabled={false} persistentFocus={false}>
                <m.div
                  className="fixed inset-0 z-[9999] flex flex-col items-center justify-end md:justify-start"
                  ref={dialogRef}
                  initial={{ opacity: disableAnimation ? 1 : 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: 'tween', duration: 0.25 }}
                  // biome-ignore lint/a11y/useSemanticElements:reason
                  role="dialog"
                  aria-label={title}
                  aria-modal="true"
                  data-testid="component:dialog"
                  data-loading={loading}
                  {...attributes}
                >
                  <div className="hidden md:block md:max-h-12 md:grow" />
                  <m.div
                    onKeyDown={handleKeyDown}
                    initial={{ y: disableAnimation ? 0 : WINDOW_HEIGHT }}
                    animate={{ y: 0 }}
                    exit={{ y: disableAnimation ? 0 : WINDOW_HEIGHT }}
                    transition={{ type: 'tween', duration: 0.25 }}
                    className={twMerge(
                      'z-20 flex w-full overflow-y-auto overflow-x-hidden',
                      size === 'piwakawaka' && 'max-w-lg',
                      size === 'kiwi' && 'max-w-2xl',
                      size === 'moa' && 'max-w-3xl',
                    )}
                    data-testid="keyboard-handler"
                  >
                    <div className="mx-auto mt-auto w-full justify-self-center md:mt-0">
                      <div
                        className={twMerge('mt-5 rounded-t-3xl bg-white', 'md:rounded-3xl', className)}
                        role="presentation"
                      >
                        <div
                          className={twMerge('sticky top-0 z-20 mx-5 bg-white pt-6', className)}
                          data-testid="component:dialog:header"
                        >
                          <Stack spacing={1}>
                            <div className="flex flex-nowrap items-center gap-0">
                              {onBack ? (
                                <Button
                                  className="!self-start" // TODO: Remove !important when Button goes to Tailwind.
                                  intent="neutral"
                                  collapsed={true}
                                  element="button"
                                  iconAfter={mdiArrowLeft}
                                  onClick={onBack}
                                  aria-label="Back"
                                  data-testid="back-via-button"
                                />
                              ) : (
                                dismissible && <div className="ml-auto h-6 w-6 shrink-0 grow-0 basis-auto" />
                              )}
                              <div className="w-full text-center">
                                <Stack spacing="half">
                                  {pretitle && <Subheading className="text-stone">{pretitle}</Subheading>}

                                  {(title || subtitle) && (
                                    <>
                                      {title && (
                                        <HeadingMid asChild={true} className="focus:outline-none" id="dialog-title">
                                          {/* biome-ignore lint/a11y/noNoninteractiveTabindex: <required for a11y> */}
                                          <h2 tabIndex={0}>{title}</h2>
                                        </HeadingMid>
                                      )}

                                      {hasSubtitleText && (
                                        <Body asChild={true} tertiary={true}>
                                          <h2 data-dd-privacy="mask">{subtitle}</h2>
                                        </Body>
                                      )}

                                      {hasSubtitleLink && (
                                        <div className="flex items-center justify-center gap-0">
                                          <Button
                                            element="a"
                                            href={subtitle.href || ''}
                                            soft={true}
                                            collapsed={true}
                                            onClick={subtitle?.onClick}
                                          >
                                            {subtitle.label}
                                          </Button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Stack>
                              </div>

                              {dismissible ? (
                                <Button
                                  className="!self-start" // TODO: Remove !imp ortant when Button goes to Tailwind.
                                  intent="neutral"
                                  collapsed={true}
                                  element="button"
                                  iconAfter={mdiClose}
                                  onClick={handleCloseClick}
                                  aria-label="Close"
                                  data-testid="close-via-button"
                                />
                              ) : (
                                onBack && <div className="ml-auto h-6 w-6 shrink-0 grow-0 basis-auto" />
                              )}
                            </div>
                            {subHeaderContent && <Stack spacing="half">{subHeaderContent}</Stack>}

                            {!subHeaderContent && <Divider />}
                          </Stack>
                        </div>

                        {loading && (
                          <Status
                            className="h-[50vh] w-full grow items-center justify-center"
                            size="moa"
                            status="loading"
                          />
                        )}
                        {!loading && Children.toArray(children).filter(isValidElement)}
                      </div>
                    </div>
                  </m.div>

                  {dismissible ? (
                    <button
                      className="fixed inset-0 z-10 h-full w-full cursor-pointer appearance-none bg-sky-900/60"
                      type="button"
                      data-testid="close-via-overlay"
                      onClick={handleCloseClick}
                      aria-label="Close"
                      tabIndex={-1}
                    />
                  ) : (
                    <div className="fixed inset-0 z-10 h-full w-full bg-sky-900/60" data-testid="close-via-overlay" />
                  )}
                </m.div>
              </FocusLock>
            </section>
          )}
        </AnimatePresence>
      </LazyMotion>,
      dialogRoot.current,
    )
  }

  console.warn(
    "Warning: Missing #portal element. For a Dialog component to render correctly, a div with an id of portal is required as a sibling of the app's #root element.",
  )

  return null
}
