import { getValueFromLocalStorage } from '../storage'
import { createEvent } from './create-event'
import { sendEvent } from './send-event'

function track(eventName: string, additionalProperties?: Record<string, unknown>) {
  const email = getValueFromLocalStorage<string | undefined>('email')
  const facilityId = getValueFromLocalStorage<string | undefined>('facilityId')

  const event = createEvent({
    additionalProperties,
    email,
    eventName,
    facilityId,
  })

  return sendEvent(event)
}

export default track
