export const APP_URL = window.location.origin
export const DEV_AUTH_URL = 'https://response.vitalerdev.com'
export const STORAGE_KEY = 'vital:response:clinician'

/**
 * Application paths
 *
 * These paths should be kept broadly in sync with the paths in the "frontend" Terraform configuration:
 *   response/terraform/modules/frontend/clinician-settings.tf:18
 */
export enum Paths {
  Alerts = 'alerts',
  Auth0Callback = '/auth0-callback',
  Auth0Login = '/auth',
  FacilitySettings = 'settings',
  FoodOrder = 'food-order',
  Invite = '/invite/sms',
  Login = '/login',
  Logout = '/logout',
  Messaging = '/:visitId/messaging/*',
  QrCode = '/invite/qr-code',
  ReInvite = '/reinvite/sms',
  ReQrCode = '/reinvite/qr-code',
  ServiceRequests = 'service-requests/*',
  SSOLandingPage = '/single-sign-on',
  TypeformForm = 'form',
  UserSettings = 'settings/user',
  UserSettingsAddPhone = 'add-phone',
  Visits = 'visits',
  VisitSubpage = '/:visitId/*',
}

/**
 * API paths
 * - [Can't be enum due to the use of window.location.origin](https://stackoverflow.com/questions/69704094/why-computed-values-are-not-permitted-in-an-enum)
 * - These paths should be kept broadly in sync with the paths in the "frontend" Terraform configuration `response/terraform/modules/frontend/clinician-settings.tf:54`
 */
export const ApiPaths = {
  Auth0Refresh: `${window.location.origin}/oauth/token`,
  Event: `${window.location.origin}/event`,
  Graphql: `${window.location.origin}/graphql`,
  Survey: `${window.location.origin}/survey`,
} as const
