import useNotifications from '../use-notifications/use-notifications'

export interface useNotificationsIndicatorArguments {
  facilityId?: string
}

const MAX_NUMBER_OF_NOTIFICATIONS_TO_SHOW = 9

const useNotificationsIndicator = ({ facilityId }: useNotificationsIndicatorArguments): string => {
  const { data: notificationsData } = useNotifications({
    facilityId,
  })

  const numberOfIncompleteNotifications = (notificationsData?.currentNotifications?.filter((n) => !n?.completed) || [])
    .length

  if (numberOfIncompleteNotifications === 0) {
    return ''
  }

  if (numberOfIncompleteNotifications > MAX_NUMBER_OF_NOTIFICATIONS_TO_SHOW) {
    return `${MAX_NUMBER_OF_NOTIFICATIONS_TO_SHOW}+`
  }

  return String(numberOfIncompleteNotifications)
}

export default useNotificationsIndicator
