import { useQuery } from '@apollo/client'
import { Constrain } from '@katoa/anatomy'
import { mdiAccountSupervisor, mdiBell, mdiCog } from '@mdi/js'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Paths } from '../../../config/app'
import GET_FACILITY_BASE_QUERY from '../../../graphql/queries/get-facility-base'
import type { FacilityBase } from '../../../graphql/type/FacilityBase'
import useNotificationsIndicator from '../../notifications/use-notification-indicator/use-notifications-indicator'
import { NavigationItem } from './navigation-item'

const Navigation: FC = () => {
  const { facilityId } = useParams()

  const numberOfIncompleteNotifications = useNotificationsIndicator({
    facilityId,
  })

  const { data } = useQuery<FacilityBase>(GET_FACILITY_BASE_QUERY, {
    variables: {
      input: {
        facilityId,
      },
    },
    skip: facilityId === 'alerts',
  })

  const facility = data?.facilityById

  if (!facility) {
    return null
  }

  const { enabledFeatures } = facility

  return (
    <nav className="flex-1">
      <Constrain size="kiwi">
        <ul className="m-0 flex list-none justify-around p-0 md:flex-col md:space-y-4 lg:space-y-4">
          <NavigationItem
            shouldAnimate={true}
            count={numberOfIncompleteNotifications}
            data-testid="navigation:alerts"
            icon={mdiBell}
            isEnabled={enabledFeatures?.serviceRequests}
            title="Alerts"
            to={Paths.Alerts}
          />

          <NavigationItem
            data-testid="navigation:patients"
            icon={mdiAccountSupervisor}
            title="Patients"
            to={Paths.Visits}
          />

          <NavigationItem
            data-testid="navigation:facility-settings"
            icon={mdiCog}
            title="Settings"
            to={Paths.FacilitySettings}
          />
        </ul>
      </Constrain>
    </nav>
  )
}

export default Navigation
