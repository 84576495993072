import { STORAGE_KEY } from './config/app'

function isStorageSupported(): boolean {
  const key = `${STORAGE_KEY}:storage-supported`

  try {
    localStorage.setItem(key, key)
    localStorage.removeItem(key)

    return true
  } catch {
    return false
  }
}

/**
 * Ensure that we have access to 'localStorage' before proceeding with app launch.
 */
export function checkBrowserSupportsRequirementsOrRedirectToUnsupportedPage(): void {
  if (!isStorageSupported()) {
    // Redirect to Unsupported Cookies page
    window.location.replace('/unsupported-cookies.html')
  }
}
