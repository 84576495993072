import type { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export const NotificationsRedirect: FC = () => {
  const { pathname, search } = useLocation()

  const newPath = `${pathname.replace('notifications', 'alerts')}/${search}`

  return <Navigate to={newPath} />
}
