import { datadogLogs } from '@datadog/browser-logs'
import { ApiPaths } from '../../config/app'
import type { Event } from './create-event'

export function sendEvent(event: Event): void {
  const success = navigator.sendBeacon(ApiPaths.Event, JSON.stringify(event))

  if (!success) {
    datadogLogs.logger.error('ERROR: Failed to send event')
  }
}
