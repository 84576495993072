import { useMutation, useQuery } from '@apollo/client/react'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { Body, Caption, ConfirmationDialog, Stack, useToast } from '@katoa/anatomy'
import { mdiBellOff, mdiBellRing } from '@mdi/js'
import { type FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TOGGLE_NOTIFICATIONS_MUTATION } from '../../../graphql/mutations/toggle-notifications'
import { ALERTS_TOGGLE_QUERY } from '../../../graphql/queries/alerts-toggle'
import type { AlertsToggleQuery } from '../../../graphql/type/AlertsToggleQuery'
import type { ToggleNotifications, ToggleNotificationsVariables } from '../../../graphql/type/ToggleNotifications'
import track from '../../../service/track/track'
import { Toggle } from '../toggle/toggle'

const AlertsToggle: FC = () => {
  const { facilityId } = useParams()

  const { data, loading } = useQuery<AlertsToggleQuery>(ALERTS_TOGGLE_QUERY, {
    variables: {
      facilityInput: {
        facilityId,
      },
      clinicianUserFacilityConfigurationInput: {
        facilityId,
      },
    },
    skip: facilityId === 'alerts',
  })

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

  const openToast = useToast()

  const facility = data?.facilityById
  const clinicianUserFacilityConfiguration = data?.clinicianUserFacilityConfiguration

  const userGroupIds = clinicianUserFacilityConfiguration?.notificationGroupIds ?? []
  const userLocationIds = clinicianUserFacilityConfiguration?.locationIds ?? []
  const facilityLocations = new Map(facility?.locations?.map((location) => [location.id, location]))
  const facilityGroups = new Map(facility?.notificationGroups?.map((group) => [group.id, group]))

  const userGroups: [string, string[]][] = []

  for (const userGroupId of userGroupIds) {
    const facilityGroup = facilityGroups.get(userGroupId)
    const locations =
      facilityGroup?.locationIds
        .filter((locationId) => userLocationIds.includes(locationId))
        .flatMap((locationId) => facilityLocations?.get(locationId)?.label ?? []) ?? []
    if (facilityGroup && locations) {
      userGroups.push([facilityGroup.label, locations.length > 0 ? locations : ['No locations chosen yet']])
    }
  }

  const [toggleNotifications] = useMutation<ToggleNotifications, ToggleNotificationsVariables>(
    TOGGLE_NOTIFICATIONS_MUTATION,
    {
      onCompleted: (result) => {
        const { notificationsEnabled } = result.toggleClinicianUserNotifications

        openToast(
          notificationsEnabled
            ? {
                title: 'Alerts are on',
                icon: mdiBellRing,
                message: 'If you subscribed to a service group, request buttons may be enabled for patients.',
              }
            : {
                intent: 'error',
                title: 'Alerts are off',
                icon: mdiBellOff,
                message: 'If you are the last member of a service group, request buttons may be disabled for patients.',
              },
        )
        datadogRum.addAction('toggle Alerts')

        track(`service-requests-switch:enable-user-${notificationsEnabled ? 'enable' : 'disable'}success`)
      },
      onError: ({ graphQLErrors }) => {
        openToast({
          intent: 'error',
          title: 'Oops!',
          message: 'Something went wrong, this setting cannot be changed right now. Please try again later.',
        })

        track('service-requests-switch:enable-user-error')
        if (graphQLErrors) {
          // biome-ignore lint/complexity/noForEach: <explanation>
          graphQLErrors.forEach((graphQlError) => {
            datadogLogs.logger.error('GraphQL Error', {}, graphQlError)
          })
        }
      },
    },
  )

  const isActive = clinicianUserFacilityConfiguration?.notificationsEnabled

  const toggleAlerts = (): void => {
    if (clinicianUserFacilityConfiguration && facilityId) {
      toggleNotifications({
        variables: {
          input: {
            active: !isActive,
            email: clinicianUserFacilityConfiguration.email,
            facilityId,
          },
        },
        optimisticResponse: {
          toggleClinicianUserNotifications: {
            email: clinicianUserFacilityConfiguration.email,
            notificationsEnabled: !isActive,
            facilityId,
            __typename: 'ClinicianUserFacilityConfiguration',
          },
        },
      })
    }
  }

  const handleConfirmDialog = (): void => {
    setOpenConfirmationDialog(false)
    toggleAlerts()
  }

  const handleOnToggle = (): void => {
    if (isActive) {
      toggleAlerts()
    } else {
      setOpenConfirmationDialog(true)
    }
  }

  if (loading) {
    return null
  }

  return (
    <>
      <Toggle
        isActive={isActive}
        iconOff={mdiBellOff}
        iconOn={mdiBellRing}
        label="Alerts"
        onToggle={handleOnToggle}
        title={`Toggle alerts for:  ${userGroups.map((g) => g[0])}`}
      />

      <ConfirmationDialog
        isVisible={openConfirmationDialog}
        onCancel={(): void => {
          setOpenConfirmationDialog(false)
        }}
        onConfirm={handleConfirmDialog}
        onRequestDismiss={(): void => {
          setOpenConfirmationDialog(false)
        }}
        title="Turn On Alerts"
        data-testid="alerts-toggle"
        cancelLabel="Cancel"
        confirmLabel="Turn on"
      >
        <Stack spacing={1}>
          {userGroups.length > 0 ? (
            <>
              <Body>The following alerts will be turned on:</Body>
              <ul className="pl-4 space-y-4">
                {userGroups.map((userGroup) => (
                  <li key={userGroup[0]}>
                    <Body>{userGroup[0]}</Body>
                    <Caption tertiary={true}>{userGroup[1].join(', ')}</Caption>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <Body>You are not currently subscribed to any alert groups.</Body>
          )}
        </Stack>
      </ConfirmationDialog>
    </>
  )
}

export default AlertsToggle
