import { gql } from '@apollo/client'
import { FACILITY_ENABLED_FEATURES_FRAGMENT } from '../fragments/facility-enabled-features'

export const LOGGED_IN_LAYOUT_QUERY = gql`
  ${FACILITY_ENABLED_FEATURES_FRAGMENT}
  query LoggedInLayoutQuery(
    $faciltyInput: FacilityByIdInput!
    $clinicianUserFacilityConfigurationInput: ClinicianUserFacilityConfigurationInput!
  ) {
    facilityById(input: $faciltyInput) {
      id
      name
      assetUrls {
        logo
      }
      ...FacilityEnabledFeaturesFragment
    }
    clinicianUserFacilityConfiguration(
      input: $clinicianUserFacilityConfigurationInput
    ) {
      email
      facilityId
      label
    }
  }
`
