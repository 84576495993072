import type { FC } from 'react'
import { Illustration, type IllustrationProps } from './illustration'

export const NoConnectionIllustration: FC<Omit<IllustrationProps, 'children'>> = (props) => {
  return (
    <Illustration {...props}>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="component:illustration:no-connection"
      >
        <g clipPath="url(#clip0_660_1005)">
          <ellipse cx="75.258" cy="88.192" rx="8.953" ry="9.401" fill="var(--highlight-colour)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.9269 30.9282C91.8839 32.3288 92.8182 33.5655 94.1607 33.9666C102.447 36.4422 110.34 40.5875 117.312 46.4022C119.857 48.5247 123.642 48.5512 125.985 46.208L129.478 42.7152C131.821 40.372 131.83 36.5505 129.326 34.3811C119.402 25.7866 107.918 19.9472 95.8736 16.863C94.0643 16.3997 92.332 17.764 92.2746 19.6308L91.9269 30.9282ZM57.3869 19.8764C57.3291 17.9954 55.5724 16.6287 53.7552 17.1177C42.0647 20.2631 30.93 26.0176 21.273 34.3811C18.7681 36.5505 18.7776 40.372 21.1208 42.7152L24.6136 46.208C26.9568 48.5512 30.7418 48.5247 33.2865 46.4022C40.0075 40.7966 47.5851 36.7426 55.5451 34.2402C56.866 33.825 57.7784 32.5987 57.7358 31.2148L57.3869 19.8764Z"
            fill="var(--highlight-colour)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.904 64.1694C90.8665 65.3902 91.5777 66.5025 92.6688 67.0514C94.7343 68.0906 96.7299 69.3166 98.6304 70.7296C101.29 72.7067 105.067 72.7506 107.41 70.4075L110.903 66.9146C113.246 64.5714 113.264 60.7436 110.692 58.6533C105.904 54.7611 100.638 51.7514 95.1092 49.6243C93.261 48.9131 91.331 50.2919 91.2701 52.2713L90.904 64.1694ZM58.3731 51.929C58.3128 49.9703 56.4196 48.5931 54.5784 49.2639C48.699 51.4061 43.0982 54.5359 38.0332 58.6533C35.4619 60.7436 35.4794 64.5714 37.8226 66.9146L41.3154 70.4075C43.6586 72.7506 47.4358 72.7067 50.095 70.7296C52.2539 69.1244 54.5357 67.7605 56.9033 66.6378C58.0313 66.1029 58.7744 64.9715 58.736 63.7237L58.3731 51.929Z"
            fill="var(--highlight-colour)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.4505 71.6278C68.379 71.6278 65.8629 69.263 65.7685 66.2874L63.8444 5.67928C63.7458 2.57136 66.3184 0 69.5264 0H80.5406C83.7487 0 86.3213 2.57136 86.2226 5.67928L84.2986 66.2874C84.2041 69.263 81.6881 71.6278 78.6166 71.6278H71.4505Z"
            fill="var(--highlight-colour)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.437 112.721C134.827 113.659 135.092 114.914 136.03 115.525C139.026 117.476 141.422 120.668 142.788 124.128C144.166 127.618 144.386 131.082 143.445 133.58C142.986 134.799 142.256 135.788 141.23 136.504C140.2 137.224 138.761 137.746 136.773 137.843C132.731 138.041 126.631 136.471 117.984 131.647C100.228 121.741 86.2437 119.18 75.4544 122.535C64.5894 125.914 57.5917 135.087 53.3618 147.126C52.9908 148.182 53.546 149.339 54.6019 149.71C55.6578 150.081 56.8145 149.525 57.1855 148.469C61.1919 137.067 67.4989 129.254 76.658 126.405C85.8927 123.533 98.6514 125.502 116.009 135.186C124.919 140.157 131.817 142.144 136.971 141.891C139.581 141.763 141.79 141.057 143.552 139.827C145.318 138.593 146.523 136.908 147.238 135.008C148.646 131.268 148.168 126.716 146.558 122.64C144.937 118.534 142.056 114.612 138.241 112.128C137.303 111.518 136.048 111.783 135.437 112.721Z"
            fill="url(#paint0_linear_660_1005)"
          />
          <path
            d="M124.132 91.0609L121.243 94.5568L117.761 91.6768C117.083 91.1169 116.766 90.1868 117.03 89.2894C117.383 88.0886 118.643 87.4004 119.845 87.754C120.15 87.8436 120.42 87.9915 120.649 88.182L124.132 91.0609Z"
            fill="#687485"
          />
          <path
            d="M115.408 101.62L112.519 105.117L108.802 102.043C108.124 101.482 107.807 100.552 108.071 99.6543C108.424 98.4535 109.685 97.7653 110.886 98.1189C111.191 98.2085 111.461 98.3564 111.691 98.5469L115.408 101.62Z"
            fill="#687485"
          />
          <path
            d="M132.478 116.455L131.806 116.809C128.653 118.468 125.041 118.83 121.634 117.828C119.855 117.304 118.23 116.442 116.808 115.264L116.758 115.223L127.032 102.988C127.179 102.813 127.294 102.608 127.362 102.376C127.628 101.474 127.111 100.529 126.211 100.264C125.542 100.067 124.85 100.302 124.429 100.804L114.137 113.057L111.025 110.486L128.363 89.5027L131.583 92.1649L128.205 96.0051C128.058 96.1793 127.943 96.3843 127.875 96.617C127.609 97.5188 128.126 98.4637 129.026 98.7287C129.695 98.9255 130.387 98.6906 130.808 98.1891L134.199 94.3341C138.358 97.8138 140.058 103.417 138.527 108.615L138.277 109.466L141.617 112.265L139.676 114.617L137.493 117.261L135.837 119.266L132.478 116.455Z"
            fill="#B3B9C1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.8444 112.721C17.4551 113.659 17.1899 114.914 16.252 115.525C13.256 117.476 10.8597 120.668 9.49335 124.128C8.11552 127.618 7.89616 131.082 8.83659 133.58C9.29543 134.799 10.026 135.788 11.0515 136.504C12.0817 137.224 13.5204 137.746 15.5086 137.843C19.5504 138.041 25.6505 136.471 34.2979 131.647C52.0535 121.741 66.0381 119.18 76.8274 122.535C87.6923 125.914 94.69 135.087 98.92 147.126C99.291 148.182 98.7357 149.338 97.6798 149.709C96.6239 150.08 95.4672 149.525 95.0962 148.469C91.0899 137.067 84.7828 129.254 75.6238 126.405C66.3891 123.533 53.6304 125.502 36.2725 135.186C27.3632 140.156 20.4648 142.144 15.3103 141.891C12.7004 141.763 10.4914 141.057 8.73005 139.827C6.96396 138.592 5.75888 136.908 5.04359 135.008C3.63577 131.268 4.1141 126.716 5.7237 122.64C7.34484 118.534 10.2257 114.612 14.0404 112.128C14.9783 111.518 16.2337 111.783 16.8444 112.721Z"
            fill="url(#paint1_linear_660_1005)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.915 85.9293C27.696 86.9259 27.5156 88.722 28.5121 89.9411L41.7795 106.171C42.7761 107.39 44.5722 107.57 45.7913 106.574C47.0103 105.577 47.1907 103.781 46.1942 102.562L32.9268 86.3322C31.9302 85.1131 30.1341 84.9328 28.915 85.9293ZM20.4755 116.809L19.8039 116.455L16.4447 119.265L14.7889 117.261L12.6057 114.617L10.6646 112.265L14.0048 109.466L13.7545 108.615C12.2241 103.417 13.9238 97.8138 18.0825 94.3341L21.4741 98.1891C21.8946 98.6905 22.5866 98.9254 23.2556 98.7286C24.1562 98.4637 24.6724 97.5188 24.4071 96.617C24.3386 96.3842 24.224 96.1792 24.077 96.005L20.6993 92.1649L23.919 89.5027L41.2565 110.486L38.1447 113.057L27.8525 100.804C27.432 100.302 26.74 100.067 26.071 100.264C25.1704 100.529 24.6542 101.474 24.9195 102.376C24.988 102.608 25.1026 102.813 25.2496 102.988L35.5234 115.223L35.4739 115.264C34.0513 116.442 32.427 117.304 30.6474 117.828C27.2407 118.83 23.6288 118.468 20.4755 116.809Z"
            fill="#B3B9C1"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_660_1005"
            x1="100.659"
            y1="111.8"
            x2="100.659"
            y2="149.825"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9DCE0" />
            <stop offset="1" stopColor="#D9DCE0" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_660_1005"
            x1="51.623"
            y1="111.8"
            x2="51.623"
            y2="149.825"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9DCE0" />
            <stop offset="1" stopColor="#D9DCE0" stopOpacity=".2" />
          </linearGradient>
          <clipPath id="clip0_660_1005">
            <rect width="150" height="150" fill="#fff" />
          </clipPath>
        </defs>
      </svg>
    </Illustration>
  )
}
