import { type Dispatch, type FC, type ReactNode, type SetStateAction, createContext, useState } from 'react'

export interface AccordionProps {
  children: ReactNode
  defaultExpandedDetail?: string
}

interface AccordionContextValues {
  currentExpandedDetail?: string | undefined
  setCurrentExpandedDetail?: Dispatch<SetStateAction<string | undefined>>
}

const AccordionContext = createContext<AccordionContextValues>({})

export const ANIMATION_DURATION = 0.25

const Accordion: FC<AccordionProps> = ({ children, defaultExpandedDetail }) => {
  const [currentExpandedDetail, setCurrentExpandedDetail] = useState(defaultExpandedDetail)

  return (
    <div>
      <AccordionContext.Provider value={{ currentExpandedDetail, setCurrentExpandedDetail }}>
        {children}
      </AccordionContext.Provider>
    </div>
  )
}

export { Accordion, AccordionContext }
