import type { JSXElementConstructor, PropsWithChildren, ReactNode } from 'react'

interface ComposeProvidersProps {
  children?: ReactNode
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  providers: JSXElementConstructor<PropsWithChildren<any>>[]
}

/**
 * Compose HOC Providers, instead of nesting.
 */
const ComposeProviders = (props: ComposeProvidersProps): JSX.Element => {
  const { providers = [], children } = props

  return (
    <>
      {providers.reduceRight(
        (acc, Provider) => (
          // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
          <Provider>{acc}</Provider>
        ),
        children,
      )}
    </>
  )
}

export default ComposeProviders
