import { mdiCheck, mdiClose } from '@mdi/js'

import type { FC, ReactNode } from 'react'
import { ButtonGroup } from '../button-group/button-group'
import { Button } from '../button/button'
import { Dialog } from '../dialog/dialog'
import { DialogContent } from '../dialog/dialog-content'
import { DialogFooter } from '../dialog/dialog-footer'

export interface ConfirmationDialogProps {
  isVisible: boolean
  disableAnimation?: boolean
  title: string
  onCancel?: () => void
  onConfirm: () => void
  onRequestDismiss?: () => void
  'data-testid': string
  cancelLabel?: string
  confirmLabel?: string
  children: ReactNode
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isVisible,
  disableAnimation = false,
  onCancel,
  onConfirm,
  onRequestDismiss,
  title,
  children,
  'data-testid': dataTestId,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
}) => {
  return (
    <Dialog
      disableAnimation={disableAnimation}
      dismissible={!!onCancel}
      isVisible={isVisible}
      title={title}
      size="piwakawaka"
      data-testid={`${dataTestId}:confirmation-dialog`}
      onRequestDismiss={onRequestDismiss}
    >
      <DialogContent>{children}</DialogContent>
      <DialogFooter>
        <ButtonGroup>
          {onCancel && (
            <Button
              element="button"
              soft={true}
              block={true}
              iconBefore={mdiClose}
              onClick={onCancel}
              data-testid={`${dataTestId}:confirmation-dialog:cancel-button`}
              data-dd-action-name="confirmation dialog cancel"
            >
              {cancelLabel}
            </Button>
          )}
          <Button
            element="button"
            block={true}
            iconBefore={mdiCheck}
            onClick={onConfirm}
            data-testid={`${dataTestId}:confirmation-dialog:confirm-button`}
            data-dd-action-name="confirmation dialog confirm"
          >
            {confirmLabel}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </Dialog>
  )
}
