import { Icon as OriginalIcon } from '@mdi/react'
import type { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import type { Colour, IconSize } from '../../types'

export interface AnatomyIconProps {
  className?: string | undefined
  path?: string | undefined
  title?: string | undefined
  colour?: Colour | undefined
  size?: IconSize | undefined
}

// Because of the way Tailwind purges unused CSS classes, the mapping can't be as simple as we'd like. e.g `text-${colour}`. Hence, these mapping functions.
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names

const mapSizeToTailwindColour = (colour: Colour) => {
  const colours = {
    midnight: 'text-midnight',
    pohutukawa: 'text-pohutukawa',
    kereru: 'text-kereru',
    storm: 'text-storm',
    stone: 'text-stone',
    'storm-thin-1': 'text-storm-thin-1',
    'storm-100': 'text-storm-100',
    'storm-400': 'text-storm-400',
    kowhai: 'text-kowhai',
    kiwi: 'text-kiwi',
    white: 'text-white',
    mist: 'text-mist',
    wind: 'text-wind',
    dust: 'text-dust',
    current: 'text-current',
    lime: 'text-lime',
  }

  return colours[colour]
}

const mapSizeToTailwindSize = (size: IconSize) => {
  const sizes = {
    1: 'w-4 h-4',
    2: 'w-5 h-5',
    3: 'w-6 h-6',
    4: 'w-8 h-8',
    5: 'w-10 h-10',
    6: 'w-12 h-12',
    7: 'w-16 h-16',
  }

  return sizes[size]
}

export const Icon: FC<AnatomyIconProps> = ({ className, path, title, colour = 'current', size = 1, ...attributes }) => {
  const tailwindSize = mapSizeToTailwindSize(size)
  const tailwindColour = mapSizeToTailwindColour(colour)

  if (!path) {
    return (
      <div
        className={twMerge('h-4 w-4 max-w-none shrink-0 grow-0', tailwindSize, tailwindColour, className)}
        title={title}
        {...attributes}
      />
    )
  }

  return (
    <OriginalIcon
      path={path}
      className={twMerge('h-4 w-4 max-w-none shrink-0 grow-0', tailwindSize, tailwindColour, className)}
      title={title ?? ''}
      {...attributes}
    />
  )
}
