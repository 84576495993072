import { gql } from '@apollo/client'
import { VISIT_DEMOGRAPHICS_FRAGMENT } from '../fragments/visit-demographics'

export const GET_CURRENT_NOTIFICATIONS_QUERY = gql`
  ${VISIT_DEMOGRAPHICS_FRAGMENT}
  query CurrentNotifications($input: CurrentNotificationsInput) {
    currentNotifications(input: $input) {
      id
      label
      requestDetails
      createdAt
      completed {
        at
        by
      }
      assigned {
        at
        to
      }
      visit {
        id
        assignedBed {
          location {
            room
          }
        }
        reasonForVisit
        facilityId
        demographics {
          ...VisitDemographicsFields
        }
        currentLocation {
          location {
            room
          }
        }
      }
      notificationGroupId
      surveyAnswers {
        type
        question
        answer
      }
    }
  }
`
