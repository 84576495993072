import { type AnchorHTMLAttributes, type ButtonHTMLAttributes, forwardRef } from 'react'
import { Link, type LinkProps } from 'react-router-dom'
import { SlotButton } from './slot-button'
import type { ButtonBaseProps } from './types'

type ButtonProps =
  | (ButtonHTMLAttributes<HTMLButtonElement> &
      ButtonBaseProps & {
        element: 'button'
      })
  | (AnchorHTMLAttributes<HTMLAnchorElement> & ButtonBaseProps & { element: 'a'; href: string })
  | (LinkProps & ButtonBaseProps & { element: 'link' })

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { element, children, ...buttonProps } = props

  if (element === 'a') {
    const { href } = props

    return (
      <SlotButton asChild={true} ref={ref} {...buttonProps}>
        <a href={href}>
          <SlotButton.Inner>{children}</SlotButton.Inner>
        </a>
      </SlotButton>
    )
  }

  if (element === 'link') {
    const { to } = props

    return (
      <SlotButton asChild={true} ref={ref} {...buttonProps}>
        <Link to={to}>
          <SlotButton.Inner>{children}</SlotButton.Inner>
        </Link>
      </SlotButton>
    )
  }

  return (
    <SlotButton asChild={false} ref={ref} {...buttonProps}>
      {children}
    </SlotButton>
  )
})

export { Button, type ButtonProps }
