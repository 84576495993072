import { twMerge } from 'tailwind-merge'
import type { ButtonBaseProps } from './types'

const getButtonClasses = ({
  soft,
  collapsed,
  intent = 'positive',
  loading,
  disabled,
  block,
  success,
  size,
  children,
  hover,
  active,
  iconAfter,
  iconBefore,
  className,
}: ButtonBaseProps): string => {
  const species = (collapsed && 'collapsed-') || (soft && 'soft-') || 'default-'
  const variant = (disabled && 'disabled') || (success && 'success') || intent
  const state = `${species}${variant}`

  const classes = [
    'inline-flex items-center justify-center self-center',
    'gap-[0.5em] py-[0.5em] px-[1.25em]',
    'border-[0.125em] border-transparent rounded-full',
    'transition-all duration-200',
    !children && 'p-[0.5em]',
    collapsed && 'p-0 gap-[0.375em] border-0',
    !collapsed && 'no-underline', // quirk to override patient app default
    block && 'flex w-full',
    size === 'piwakawaka' && 'text-xs',
    size === 'kiwi' && 'text-base',
    loading && 'pointer-events-none',
    hover && 'hover',
    active && 'active',
    children && !collapsed && iconBefore && '[&>*:first-child]:ml-[-0.5em]',
    children && !collapsed && iconAfter && '[&>*:last-child]:mr-[-0.5em]',
  ]

  if (disabled) {
    return twMerge(
      classes,
      'cursor-not-allowed',
      state === 'soft-disabled' && 'border-disabled-border text-disabled-text',
      state === 'default-disabled' && 'bg-disabled-background text-disabled-text ',
      state === 'collapsed-disabled' && 'text-disabled-text',
      className,
    )
  }

  return twMerge(
    classes,
    state.startsWith('soft') &&
      `bg-white hover:border-transparent hover:text-white active:border-transparent active:text-white 
      [&.active]:border-transparent [&.active]:text-white
      [&.hover]:border-transparent [&.hover]:text-white
      `,
    state.startsWith('default') && 'text-white',
    state.startsWith('collapsed') &&
      'bg-transparent underline hover:no-underline active:no-underline [&.active]:no-underline [&.hover]:no-underline',
    // -- positive --
    state === 'default-positive' &&
      `bg-primary-background hover:bg-primary-background-light 
      active:bg-primary-background-dark [&.active]:bg-primary-background-dark 
      [&.hover]:bg-primary-background-light`,
    state === 'soft-positive' &&
      `border-primary-background text-primary-background
       hover:bg-primary-background-light active:bg-primary-background-dark 
       [&.active]:bg-primary-background-dark [&.hover]:bg-primary-background-light 
       `,
    state === 'collapsed-positive' &&
      `text-primary-background hover:text-primary-background-light active:text-primary-background-dark
      [&.active]:text-primary-background-dark [&.hover]:text-primary-background-light
      `,
    // -- neutral --
    state === 'default-neutral' &&
      `bg-mist text-storm hover:bg-[hsl(180deg_20%_95%)] active:bg-[hsl(180deg_20%_75%)]
      [&.active]:bg-[hsl(180deg_20%_75%)] [&.hover]:bg-[hsl(180deg_20%_95%)]
      `,
    state === 'soft-neutral' &&
      `border-mist text-storm hover:bg-mist hover:text-storm active:bg-[hsl(180deg_20%_75%)] 
      active:text-storm [&.active]:bg-[hsl(180deg_20%_75%)] [&.active]:text-storm
      [&.hover]:bg-mist [&.hover]:text-storm
      `,
    state === 'collapsed-neutral' &&
      `text-storm hover:text-[hsl(215deg_54%_16%_/_75%)] active:text-[hsl(215deg_54%_16%_/_90%)]
        [&.active]:text-[hsl(215deg_54%_16%_/_90%)] [&.hover]:text-[hsl(215deg_54%_16%_/_75%)]
        `,
    // -- destructive --
    state === 'soft-destructive' &&
      `border-pohutukawa text-pohutukawa hover:bg-[hsl(14deg_81%_40%)] active:bg-[hsl(14deg_81%_35%)]
      [&.active]:bg-[hsl(14deg_81%_35%)] [&.hover]:bg-[hsl(14deg_81%_40%)]`,

    state === 'default-destructive' &&
      `bg-pohutukawa hover:bg-[hsl(14deg_81%_40%)] active:bg-[hsl(14deg_81%_35%)]
      [&.active]:bg-[hsl(14deg_81%_35%)] [&.hover]:bg-[hsl(14deg_81%_40%)]
      `,
    state === 'collapsed-destructive' &&
      `text-pohutukawa hover:text-[hsl(14deg_81%_40%)] active:text-[hsl(14deg_81%_35%)]
      [&.active]:text-[hsl(14deg_81%_35%)] [&.hover]:text-[hsl(14deg_81%_40%)]`,
    // -- success --
    state === 'default-success' && 'bg-kiwi',
    state === 'soft-success' && 'border-kiwi text-kiwi hover:bg-kiwi [&.hover]:bg-kiwi',
    state === 'collapsed-success' && 'text-kiwi',
    soft && collapsed && state.endsWith('neutral') && 'text-inherit hover:text-inherit', // quirk
    className,
  )
}

export { getButtonClasses }
