import type { ElementType, FC, ReactElement, ReactNode } from 'react'
import { Body } from '../typography/body'

export interface EmptyTextProps {
  children?: ReactNode
  element?: ElementType
}

export const EmptyText: FC<EmptyTextProps> = ({ children, ...attributes }): ReactElement => {
  return (
    <Body secondary={true} {...attributes}>
      {children}
    </Body>
  )
}
