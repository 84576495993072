import { datadogLogs } from '@datadog/browser-logs'
import { type Result, err, ok } from 'neverthrow'
import { ApiPaths } from '../config/app'
import { addValueToLocalStorage, getValueFromLocalStorage } from './storage'

// Error code descriptions at https://auth0.com/docs/api/authentication#standard-error-responses
type TokenEndpointErrorType =
  | 'access_denied'
  | 'endpoint_disabled'
  | 'invalid_request'
  | 'invalid_client'
  | 'invalid_scope'
  | 'invalid_grant'
  | 'method_not_allowed'
  | 'requires_validation'
  | 'temporarily_unavailable'
  | 'too_many_requests'
  | 'unauthorized_client'
  | 'unsupported_grant_type'
  | 'unsupported_response_type'

interface RawTokenEndpointError {
  error: TokenEndpointErrorType
  error_description: string
}

export interface TokenQueryError {
  name?: 'FetchError'
  message?: string
  stack?: string
  type: TokenEndpointErrorType | 'invalid-json' | 'missing-config'
}

export interface TokenQueryResponse {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token?: string // Refresh Token is not returned when 'refresh_token' is requested.
  token_type: 'Bearer'
}

/**
 * Decode the payload section of a JWT Token, returning the email & facilityId, or undefined as default values.
 */

interface MultiFaclityJWTValues {
  email: string
  'vital/health_entity_id': string
  iss: string
}

export function getValuesFromJwtToken(jwtToken: string): {
  email?: string
  issuer?: string
} {
  try {
    const [, payload] = jwtToken.split('.')

    if (!payload) {
      // See PE-8403. If this turns out to be the cause of the errors, then
      // we can probably return undefined values instead of throwing.
      throw new Error(`Malformed JWT Token: ${JSON.stringify(jwtToken)}`)
    }
    const decodedJwtPayload = atob(payload)

    let parsedValues: MultiFaclityJWTValues

    try {
      parsedValues = JSON.parse(decodedJwtPayload) as MultiFaclityJWTValues
    } catch {
      // See PE-8403. If this turns out to be the cause of the errors, then
      // we can probably return undefined values instead of throwing.
      throw new Error('Could not parse decoded JWT Token as JSON')
    }

    if ('email' in parsedValues && 'vital/health_entity_id' in parsedValues) {
      return {
        email: parsedValues.email,
        issuer: parsedValues.iss,
      }
    }

    return { email: undefined, issuer: undefined }
  } catch (error) {
    datadogLogs.logger.error('Error decoding JWT Token', { error })

    return { email: undefined, issuer: undefined }
  }
}

export const saveDataToStorage = ({
  idToken,
  refreshToken,
}: {
  idToken: string
  refreshToken?: string
}): void => {
  const { email } = getValuesFromJwtToken(idToken)

  // Save ID Token to storage
  addValueToLocalStorage<string>('id-token', idToken)

  // Ensure Refresh Token exists before setting it. Otherwise, we may overwrite
  // a valid token with 'undefined', which is not correct.
  if (refreshToken) {
    addValueToLocalStorage<string>('refresh-token', refreshToken)
  }

  // Save computed data to storage
  addValueToLocalStorage<string | undefined>('email', email)

  // Set current user in Datadog
  datadogLogs.setUser({ email })
  datadogLogs.setUserProperty('type', 'clinician')
}

/**
 * A little 'middleware' to handle the error cases of the token query.
 */
export async function handleTokenQueryError(response: Response): Promise<Result<TokenQueryResponse, TokenQueryError>> {
  // Wrap the .json functionality in try/catch in case non-JSON returned
  const responseText = await response.text()
  let result: TokenQueryResponse | RawTokenEndpointError

  try {
    result = JSON.parse(responseText) as unknown as TokenQueryResponse | RawTokenEndpointError
  } catch (error) {
    // Log exceptions if the response.json() call fails
    const message =
      typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string'
        ? `Error parsing response JSON. Error: "${error.message}". Response: "${responseText}"`
        : `Error parsing response JSON. Response: "${responseText}"`
    const queryError = {
      name: 'FetchError',
      message,
      type: 'invalid-json',
      stack: (error as TokenQueryError).stack,
    } satisfies TokenQueryError

    datadogLogs.logger.warn('Error parsing response JSON', queryError)

    return err(queryError)
  }

  // Return ok result if fetch succeeded
  if (response.ok) {
    return ok(result as TokenQueryResponse)
  }

  // Create generic 'error' object
  const rawError = result as RawTokenEndpointError
  const errorType = rawError.error

  const error: TokenQueryError = {
    message: rawError.error_description,
    type: errorType,
  }

  // Log 'critical' exceptions
  const isCritical = ['invalid_request', 'invalid_client', 'unauthorized_client', 'unsupported_grant_type'].includes(
    errorType,
  )

  if (isCritical) {
    datadogLogs.logger.warn('Critical error in token query', error)
  }

  // Otherwise, return the error cases
  return err(error)
}

/**
 * Promise chain 'middleware' to save token data and decoded payload to Local Storage.
 * Also configures Datadog with the logged-in user details.
 */
export function handleTokenEndpointResponse(
  response: Result<TokenQueryResponse, TokenQueryError>,
): Result<TokenQueryResponse, TokenQueryError> {
  // Process successful response, save data to storage
  if (response.isOk()) {
    const { id_token: idToken, refresh_token: refreshToken } = response.value

    saveDataToStorage({ idToken, refreshToken })
  }

  // Return original response to ensure the chain doesn't break
  return response
}

/**
 * Query Auth0 endpoint for ID Token or Refresh Token.
 * Handles differentiating different levels of error/exception.
 */
export async function queryAuth0Endpoint(bodyProps: {
  email?: string
  refresh_token: string
}): Promise<Response> {
  const body = {
    email: bodyProps.email,
    refresh_token: bodyProps.refresh_token,
  }

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }

  // Make fetch request
  return fetch(ApiPaths.Auth0Refresh, request)
}

/**
 * Query the Auth0 endpoint for a Refresh Token.
 */
export async function refreshIdTokenAndSaveToStorage(
  refreshToken: string,
): Promise<Result<TokenQueryResponse, TokenQueryError>> {
  const email = getValueFromLocalStorage<string | undefined>('email')

  const body = { email, refresh_token: refreshToken }

  const idToken = getValueFromLocalStorage<string | undefined>('id-token')

  if (!idToken) {
    datadogLogs.logger.warn('refreshIdTokenAndSaveToStorage: No ID Token found in Local Storage')
  }

  return queryAuth0Endpoint(body).then(handleTokenQueryError).then(handleTokenEndpointResponse)
}
