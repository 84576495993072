const getRedirectFromSearch = (searchParams: string): { pathname: string; search: string } | false => {
  const queryParams = new URLSearchParams(searchParams)
  const redirectQueryParam = queryParams.get('rd')

  if (redirectQueryParam) {
    queryParams.delete('rd')

    const pathname = redirectQueryParam?.startsWith('/') ? `${redirectQueryParam}` : `/${redirectQueryParam}`

    const search = queryParams.toString() ? `?${queryParams.toString()}` : ''

    return {
      pathname,
      search,
    }
  }

  return false
}

export default getRedirectFromSearch
