import type { FC, HTMLProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface DialogContentProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode
}

export const DialogContent: FC<DialogContentProps> = ({ children, className, ...attributes }) => (
  <div
    className={twMerge('mx-5 shrink-0 grow-0 bg-white py-5', className)}
    data-testid="component:dialog:content"
    {...attributes}
  >
    {children}
  </div>
)
