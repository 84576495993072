import { forwardRef, useState } from 'react'
import { TextInput, type TextInputProps } from '../text-input/text-input'

export type PhoneNumberInputProps = TextInputProps & {
  validation?: (data: boolean) => void
}

export const phoneNumberInputPattern =
  /^(\+\d{1,3})?(\(\d{3}\)\s?\d{3}\s?\d{4}|\(\d{3}\)\s?\d{3}-?\d{4}|\d{3}(\s?|-?)\d{3}(\s?|-?)\d{4}|\d{10})$/

export const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ validation, className, ...props }, ref) => {
    const [isValidPhoneNumber, setIsValidPhone] = useState(true)
    const handleValidation = (value: boolean) => {
      validation?.(value)
      setIsValidPhone(value)
    }

    return (
      <TextInput
        {...props}
        ref={ref}
        className={className}
        aria-invalid={!isValidPhoneNumber}
        type="tel"
        pattern={phoneNumberInputPattern.source}
        validation={handleValidation}
      />
    )
  },
)
