import type { FC, ReactElement, ReactNode } from 'react'
import { HeadingMid } from '../typography/heading-mid'

export interface EmptyHeadingProps {
  asChild?: boolean
  children?: ReactNode
}

export const EmptyHeading: FC<EmptyHeadingProps> = ({ asChild, children, ...attributes }): ReactElement => {
  return (
    <HeadingMid asChild={true} {...attributes}>
      {asChild ? children : <h1>{children}</h1>}
    </HeadingMid>
  )
}
