import { gql } from '@apollo/client'

export const TOGGLE_NOTIFICATIONS_MUTATION = gql`
  mutation ToggleNotifications($input: ToggleClinicianUserNotificationsInput!) {
    toggleClinicianUserNotifications(input: $input) {
      email
      facilityId
      notificationsEnabled
    }
  }
`
