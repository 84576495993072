import { Slot } from '@radix-ui/react-slot'
import type { ComponentPropsWithoutRef, FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface HeadingMidProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   */
  asChild?: boolean
}

export const HeadingMid: FC<HeadingMidProps> = ({ asChild, children, className, ...attributes }) => {
  const Element = asChild ? Slot : 'h2'

  return (
    <Element {...attributes} className={twMerge('text-md leading-8 font-semibold', className)}>
      {children}
    </Element>
  )
}
