import { datadogLogs } from '@datadog/browser-logs'
import { createRoot } from 'react-dom/client'
import App from './app/app'
import { initInstrumentation } from './instrumentation'
import { checkBrowserSupportsRequirementsOrRedirectToUnsupportedPage } from './support'
import './style.css'

// https://mswjs.io/docs/recipes/deferred-mounting
async function enableMocking() {
  if (import.meta.env.MODE !== 'test') {
    return null
  }

  const { setupMocks } = await import('./mocks/setup')

  return setupMocks()
}

// Does the user meet all technical requirements to run the app?
checkBrowserSupportsRequirementsOrRedirectToUnsupportedPage()

// Configure Instrumentation
initInstrumentation()

// https://mswjs.io/docs/recipes/deferred-mounting
enableMocking().then(
  () => {
    // Start React App
    const rootElement = document.getElementById('root')

    if (rootElement) {
      const reactRoot = createRoot(rootElement)

      reactRoot.render(<App />)
    } else {
      datadogLogs.logger.error('Cannot find a root element to mount our React App into')
    }
  },
  (error) => {
    datadogLogs.logger.error('Error starting mocks', {}, error instanceof Error ? error : undefined)
  },
)
