import { Slot } from '@radix-ui/react-slot'
import type { ComponentPropsWithoutRef, FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface SubheadingProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   */
  asChild?: boolean
}

export const Subheading: FC<SubheadingProps> = ({ asChild, children, className, ...attributes }) => {
  const Element = asChild ? Slot : 'div'

  return (
    <Element
      {...attributes}
      className={twMerge('text-sm leading-6 font-extrabold tracking-[1px] uppercase text-balance', className)}
    >
      {children}
    </Element>
  )
}
