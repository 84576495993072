import { gql } from '@apollo/client'

export const FACILITY_ENABLED_FEATURES_FRAGMENT = gql`
  fragment FacilityEnabledFeaturesFragment on Facility {
    enabledFeatures {
      serviceRequests
      providerPatientMessaging
      reasonForVisit
    }
  }
`
