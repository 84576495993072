import { type ComponentProps, type FC, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import ArrowTooltip from './arrow-tooltip'

const AnchorTooltip: FC<ComponentProps<typeof ArrowTooltip>> = forwardRef(
  ({ align, position, status, children, className, arrowOverlap, ...dataAtribs }, ref) => {
    return (
      <ArrowTooltip
        ref={ref}
        align={align}
        position={position}
        status={status}
        arrowOverlap={arrowOverlap}
        className={twMerge(
          'border border-dashed border-mist',
          `
      after:top-[-22px]
      after:h-[20px]
      after:w-0
      after:-translate-x-1/2
      after:rotate-0
      after:border after:border-dashed after:border-mist
    `,
          className,
        )}
        {...dataAtribs}
      >
        {children}
      </ArrowTooltip>
    )
  },
)

export default AnchorTooltip
