import { Constrain, Empty, EmptyHeading, NoFacilityIllustration, Stack } from '@katoa/anatomy'
import { type FC, useEffect } from 'react'
import { FacilityPicker } from '../../app/ui-components/facility-picker/facility-picker'
import track from '../../service/track/track'

export const NoFacilityPage: FC = () => {
  useEffect(() => {
    document.title = 'Facility Not Found | Vital CareAssist'
    track('no-facility-page-loaded')
  }, [])

  return (
    <div className="m-auto p-4">
      <Constrain size="piwakawaka">
        <Stack spacing={1}>
          <Empty>
            <NoFacilityIllustration highlight="kereru" />
            <EmptyHeading>This Facility does not exist.</EmptyHeading>
          </Empty>
          <FacilityPicker />
        </Stack>
      </Constrain>
    </div>
  )
}
