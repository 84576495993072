import { mdiAlertCircle, mdiCheck, mdiClose, mdiInformation } from '@mdi/js'
import { m } from 'framer-motion'
import { type FC, Fragment, type ReactElement, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import { Stack } from '../stack/stack'
import { Caption } from '../typography/caption'

export interface ToastProps {
  autoDismiss?: boolean | undefined
  autoDismissLength?: number | undefined
  'data-testid'?: string | undefined
  icon?: string | undefined
  intent?: 'error' | 'informative' | 'success' | undefined
  killAnimation?: boolean | undefined
  message?: string | undefined
  /** Function to be called when the toast is dismissed */
  onRequestDismiss?: (() => void) | undefined
  title?: string | undefined
}

export const Toast: FC<ToastProps> = ({
  autoDismiss = true,
  autoDismissLength = 8000,
  'data-testid': dataTestId,
  icon,
  intent = 'success',
  killAnimation = false,
  title,
  message,
  onRequestDismiss,
}): ReactElement | null => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect((): (() => void) => {
    if (autoDismiss && onRequestDismiss) {
      timer.current = setTimeout(onRequestDismiss, autoDismissLength)
    }

    return (): void => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [autoDismiss, autoDismissLength, onRequestDismiss])

  let iconPath: string

  if (icon) {
    iconPath = icon
  } else {
    switch (intent) {
      case 'error':
        iconPath = mdiAlertCircle
        break

      case 'success':
        iconPath = mdiCheck
        break

      case 'informative':
        iconPath = mdiInformation
        break
    }
  }

  return (
    <m.div
      initial={killAnimation ? { y: 0, opacity: 1 } : { y: '100%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{
        y: '100%',
        opacity: 0,
        transition: { duration: 0.2, type: 'tween' },
      }}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 50,
        duration: 0.2,
      }}
      data-testid={dataTestId || 'components:toast'}
      className="mx-auto my-0 flex w-full gap-3 rounded-xl border bg-[#070e18eb] p-4 text-white opacity-100 shadow-xl"
    >
      <Icon
        path={iconPath}
        size={3}
        colour="white"
        className={twMerge(
          'rounded-full bg-red-400 p-1',
          intent === 'error' && 'bg-pohutukawa',
          intent === 'informative' && 'bg-kereru',
          intent === 'success' && 'bg-kiwi',
        )}
      />

      <Stack spacing="quarter">
        <h5>
          <strong>{title}</strong>
        </h5>

        <Caption className="opacity-60">
          {message?.split('\n').map((line) => (
            <Fragment key={line}>
              {line}
              <br />
            </Fragment>
          ))}
        </Caption>
      </Stack>

      <Button
        className="!self-start opacity-60"
        element="button"
        collapsed={true}
        iconAfter={mdiClose}
        intent="neutral"
        onClick={(): void => {
          if (onRequestDismiss) {
            onRequestDismiss()
          }
        }}
        soft={true}
        data-testid="components:toast:dismiss"
        aria-label="Dismiss"
      />
    </m.div>
  )
}
