import { Status } from '@katoa/anatomy'
import { type FC, useEffect, useState } from 'react'

const MILLISECONDS_BEFORE_DISPLAY = 300

const DelayedLoader: FC = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), MILLISECONDS_BEFORE_DISPLAY)

    return (): void => {
      clearTimeout(timeout)
    }
  }, [])

  if (!show) {
    return null
  }

  return <Status status="loading" size="moa" className="m-auto" />
}

export default DelayedLoader
