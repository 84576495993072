import { gql } from '@apollo/client'

export const VISIT_DEMOGRAPHICS_FRAGMENT = gql`
  fragment VisitDemographicsFields on Demographics {
    dob
    firstName
    lastName
    mobileNumber
    homeNumber
    sex
  }
`
