import { Slot } from '@radix-ui/react-slot'
import type { ComponentPropsWithoutRef, FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface CaptionProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   */
  asChild?: boolean
  secondary?: boolean
  tertiary?: boolean
}

export const Caption: FC<CaptionProps> = ({ asChild, children, secondary, tertiary, className, ...attributes }) => {
  const Element = asChild ? Slot : 'div'

  return (
    <Element
      {...attributes}
      className={twMerge('text-xs leading-5', secondary && 'text-midnight', tertiary && 'text-stone', className)}
    >
      {children}
    </Element>
  )
}
