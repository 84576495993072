import { AnimatePresence, m } from 'framer-motion'
import { type FC, type ReactNode, createContext, useCallback, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { v4 as uuid } from 'uuid'
import { LazyMotion } from '../lazy-motion/lazy-motion'
import { Toast, type ToastProps } from './toast'

export interface ToastProviderProps {
  children?: ReactNode
}

type ToastWithId = ToastProps & { id: string }
export type OpenToast = (toast: ToastProps) => void
type ToastCache = ToastWithId[]

export const ToastContext = createContext<OpenToast | (() => void)>(() => undefined)

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastCache>([])

  const toastRoot = useRef(document.getElementById('portal'))

  // if total number of toasts > 3 then start remiving last ones

  const open: OpenToast = useCallback((options) => {
    const id = uuid()

    setToasts((items) => {
      const updatedToasts = [...items, { ...options, id }]

      return updatedToasts.slice(-3)
    })
  }, [])

  const remove = (id: string): void => {
    setToasts((items) => [...items.filter((t) => t.id !== id)])
  }

  if (toastRoot.current) {
    return (
      <LazyMotion>
        <ToastContext.Provider value={open}>{children}</ToastContext.Provider>
        {toastRoot.current
          ? createPortal(
              <div className="fixed bottom-16 left-1/2 z-[10000] w-full max-w-lg -translate-x-1/2 space-y-2">
                <AnimatePresence>
                  {toasts.map(
                    (toast, idx) =>
                      toast && (
                        <m.div
                          className="w-full px-4 py-0"
                          key={toast.id}
                          initial={{ maxHeight: 0, opacity: 1 }}
                          animate={{
                            maxHeight: 400,
                            opacity: idx + 1 === toasts.length ? 1 : 0.9,
                            transition: { duration: 0.4 },
                          }}
                          exit={{
                            maxHeight: 0,
                            opacity: 0,
                            transition: { duration: 0.4 },
                          }}
                          transition={{ ease: 'easeInOut' }}
                        >
                          <Toast
                            key={toast.id}
                            autoDismiss={toast.autoDismiss}
                            autoDismissLength={toast.autoDismissLength}
                            intent={toast.intent}
                            title={toast.title}
                            message={toast.message}
                            icon={toast.icon}
                            onRequestDismiss={(): void => {
                              if (toast.onRequestDismiss) {
                                toast.onRequestDismiss()
                              }
                              remove(toast.id)
                            }}
                            data-testid={toast['data-testid']}
                          />
                        </m.div>
                      ),
                  )}
                </AnimatePresence>
              </div>,
              toastRoot.current,
            )
          : null}
      </LazyMotion>
    )
  }

  console.warn(
    "Warning: Missing #portal element. For the ToastProvider component to render correctly, a div with an id of portal is required as a sibling of the app's #root element.",
  )

  return null
}
