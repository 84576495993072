import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const { VITE_ENVIRONMENT, VITE_VERSION } = import.meta.env

const sharedDatadogConfig = {
  clientToken: 'pubd6e945d1071ed0afa4ecd31d7739a98c',
  site: 'datadoghq.com',
  service: 'clinician',
  env: VITE_ENVIRONMENT,
  version: VITE_VERSION,
  sessionSampleRate: 100,
}

function initRUM(): void {
  if (VITE_ENVIRONMENT === 'production') {
    datadogRum.init({
      ...sharedDatadogConfig,
      applicationId: '1aeb43db-0312-43d2-ba47-a01e9dc31ee9',
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      workerUrl: new URL('@datadog/browser-worker', import.meta.url).href,
      defaultPrivacyLevel: 'mask-user-input',
      compressIntakeRequests: true,
    })
  }
}

function initLogging(): void {
  datadogLogs.init({
    ...sharedDatadogConfig,
    forwardErrorsToLogs: true,
    beforeSend: (log) => {
      if (['Fetch error POST', 'XHR error POST'].some((ignoredLog) => log.message.includes(ignoredLog))) {
        return false
      }

      if (VITE_ENVIRONMENT !== 'production') {
        console.info('[FAKE LOG TO DATADOG]:', log)

        return false
      }

      return true
    },
  })
}

export function initInstrumentation() {
  initRUM()
  initLogging()
}
